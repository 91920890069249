import { reactive } from "vue"

export const store = reactive({
    showSidebar: true,
    sidebarSize: "full",
    showHeader: true,
    showBackButton: false,
    showGroupButton: false,
    user: null,
    workspace: null,
    backUrl: null,
    showDebugger: false,
    groups: [],
    accountPanelUrl: process.env.VUE_APP_ACCOUNT_URL
})
