<template>
    <div>
        <div class="page-wrapper">
            <div class="page-body">
                <div class="row g-2">
                    <div class="col">
                        <div class="card">
                            <div class="card-header">
                                <div class="col">
                                    <h3 class="card-title">
                                        <i class="ti ti-list-check"></i>
                                        Editar fila interna
                                    </h3>
                                </div>
                            </div>

                            <div class="card-body">
                                <div class="row mb-3">
                                    <div class="col">
                                        <label class="form-label required">Nome da fila</label>
                                        <input type="text" class="form-control"
                                            placeholder="Exemplo: Sincronização de pedidos" v-model="queue.name"
                                            id="nameInput" />
                                    </div>
                                    <div class="col-auto">
                                        <label class="form-label">Grupo</label>
                                        <div class="input-group">
                                            <span class="input-group-text">
                                                <i class="ti ti-folders"></i>
                                            </span>
                                            <select class="form-select" v-model="queue.group">
                                                <option value="">Sem grupo</option>
                                                <option v-for="group in groupsList" :value="group._id" :key="group._id">{{ group.name }}</option>
                                            </select>
                                        </div>
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col">
                                        <label class="form-label required">Limite de execuções por
                                            segundo</label>
                                        <input type="text" class="form-control" placeholder="1"
                                            v-model="queue.max_per_second" id="maxPerSecondInput" />
                                        <span class="form-hint">Número máximo de consumos por
                                            segundo</span>
                                    </div>
                                </div>
                            </div>

                            <div class="card-body" v-if="validationErrors.length">
                                <div class="row">
                                    <div class="col">
                                        <h5>Erros encontrados:</h5>
                                        <ul>
                                            <li v-for="error in validationErrors" :key="error" class="text-red">
                                                {{ error }}
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                            </div>

                            <div class="card-footer">
                                <div class="row">
                                    <div class="col-auto align-right">
                                        <button class="btn btn-primary" @click="saveQueue" id="saveQueueButton">
                                            <i class="ti ti-device-floppy"></i>
                                            Salvar fila
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { store } from "../store"
import Api from "../services/api"
import EventBus from "../services/event-bus"
import { limitGroupName, parseValidGroup } from "@/services/utils";

export default {
    name: "QueueEditPage",
    data() {
        return {
            queue: {
                name: "",
                max_per_second: 1
            },
            validationErrors: []
        }
    },
    async mounted() {
        store.showSidebar = true
        store.showHeader = true
        store.showBackButton = true
        store.backUrl = "/queues"

        var groupsResponse = await Api.groups.list(1, 100)
        this.groupsList = groupsResponse.data.items.map((group) => limitGroupName(group))

        const selectedGroup = parseValidGroup(localStorage.getItem("selectedGroup"), this.groupsList)

        // new
        if (!this.$route.params.id) {
            this.queue = {
                name: "",
                max_per_second: 1,
                group: selectedGroup
            }
            return
        }

        var response = await Api.queues.get(this.$route.params.id)
        this.queue = response.data

        const parsedGroup = parseValidGroup(this.queue.group, this.groupsList)
        this.queue.group = parsedGroup
    },
    methods: {
        async saveQueue() {
            var errors = this.validate()
            this.validationErrors = errors

            if (errors.length) {
                return
            }
            
            try {
                // new
                if (!this.$route.params.id) {
                    var response = await Api.queues.create({
                        ...this.queue,
                        group: this.queue.group || null
                    })
                    this.queue = response.data
                } else {
                    // update
                    this.queue.apply_default_values_new_fields =
                        this.applyDefaultValuesOnNewFields
                    await Api.queues.update(this.queue._id, {
                        ...this.queue,
                        group: this.queue.group || null
                    })
                }

                EventBus.emit("message", {
                    type: "success",
                    message: "A file interna foi salva com sucesso!"
                })

                this.$router.push("/queues/view/" + this.queue._id)
            } catch (e) {
                var message = e.message
                if (e.response && e.response.data && e.response.data.message) {
                    message = e.response.data.message
                }

                if (e.response && e.response.data && e.response.data.error) {
                    message = e.response.data.error
                }

                if (message.indexOf("name_1 dup key") != -1) {
                    message = "Já existe uma file interna com este nome."
                }

                if (message.indexOf("identifier_1 dup key") != -1) {
                    message =
                        "Já existe uma file interna com este identificador."
                }

                EventBus.emit("message", {
                    type: "danger",
                    message:
                        "Ocorreu um erro ao salvar a file interna: " + message
                })
            }
        },

        validate() {
            var errors = []

            if (!this.queue.name) {
                errors.push(`O campo "Nome da fila" é obrigatório.`)
            }

            return errors
        }
    }
}
</script>
