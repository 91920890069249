<template>
    <div>
        <div class="card">
            <div class="card-header">
                <div class="col">
                    <h3 class="card-title">
                        <i class="ti ti-cpu"></i>
                        Tabelas de banco de dados
                    </h3>
                </div>
                <div class="col-auto">
                    <a href="/database/tables/new" class="btn btn-primary" id="newTableButton">
                        <i class="ti ti-plus"></i>
                        Criar tabela de dados
                    </a>
                </div>
            </div>

            <div class="card-body">
                <div class="row">
                    <div class="col-3 mb-2">
                        <input type="text" class="form-control" v-model="filter.query" @change="updateFilter"
                            placeholder="Buscar tabelas" id="searchTableButton" />
                    </div>
                </div>
            </div>

            <div class="card-body">
                <div v-if="tableList.length">
                    <table class="table table-vcenter card-table">
                        <thead>
                            <tr>
                                <th>Nome</th>
                                <th>Grupo</th>
                                <th>Registros</th>
                                <th>Criação</th>
                                <th>Atualização</th>
                                <th>Ações</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr v-for="table in tableList" :key="table._id">
                                <td>{{ table.name }}</td>
                                <td>
                                    <span v-if="!groupName(table.group)" class="badge bg-gray-lt text-muted">Sem grupo</span>
                                    <span v-else class="badge bg-blue-lt">{{ groupName(table.group) }}</span>
                                </td>
                                <td>{{ table.entities_count }}</td>
                                <td>{{ formatDate(table.created_at) }}</td>
                                <td>{{ formatDate(table.updated_at) }}</td>
                                <td>
                                    <a :href="'/database/entities/' + table._id
                                        " class="btn btn-sm">
                                        <i class="ti ti-list"></i>
                                        Ver registros
                                    </a>
                                    <span>&nbsp;</span>
                                    <span class="dropdown">
                                        <button class="btn btn-sm dropdown-toggle tableOptionsDropdown"
                                            data-bs-toggle="dropdown">
                                            Opções
                                        </button>
                                        <div class="dropdown-menu">
                                            <a :href="'/database/tables/edit/' +
                                                table._id
                                                " class="dropdown-item editTableButton">
                                                <i class="ti ti-pencil"></i>
                                                Editar estrutura
                                            </a>
                                            <a href="#" class="dropdown-item duplicateTableButton" @click="
                                                duplicateTable(table._id)
                                                ">
                                                <i class="ti ti-copy"></i>
                                                Duplicar
                                            </a>
                                            <a href="#" class="dropdown-item exportStructureButton" @click="
                                                exportTableStructure(
                                                    table._id
                                                )
                                                ">
                                                <i class="ti ti-download"></i>
                                                Exportar estrutura
                                            </a>
                                            <a :href="'/database/tables/delete/' +
                                                table._id
                                                " class="dropdown-item text-red deleteTableButton">
                                                <i class="ti ti-trash"></i>
                                                Excluir
                                            </a>
                                        </div>
                                    </span>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>

            <div class="card-body" v-if="!tableList.length && !filter.query">
                <div class="empty">
                    <p class="empty-title">Nenhuma tabela criada ainda.</p>
                    <p class="empty-subtitle text-muted">
                        Clique no botão abaixo para criar sua primeira tabela!
                    </p>
                    <div class="empty-action">
                        <a href="/database/tables/new" class="btn btn-primary" id="newTableButton">
                            <i class="ti ti-plus"></i>
                            Criar tabela de dados
                        </a>
                    </div>
                </div>
            </div>

            <div class="card-body" v-if="!tableList.length && filter.query">
                <div class="empty bg-muted-lt">
                    <p class="empty-title">
                        Nenhuma tabela encontrada para esta busca.
                    </p>
                </div>
            </div>

            <div class="card-footer">
                <Pagination :key="paginationKey" :showPerPage="showPerPage" :currentPage="currentPage" :total="total"
                    v-if="total > showPerPage" @pageChange="updatePage" />
            </div>
        </div>
    </div>
</template>

<script>
import { store } from "../store"
import moment from "moment"
import Api from "../services/api"
import Pagination from "./Pagination"
import EventBus from "../services/event-bus"

export default {
    name: "DbTablesList",
    components: {
        Pagination
    },
    data() {
        return {
            tableList: [],
            filter: {
                query: ""
            },
            paginationKey: 0,
            showPerPage: 10,
            currentPage: 1,
            total: 0
        }
    },
    async mounted() {
        this.currentPage = parseInt(this.$route.query.page) || 1
        this.filter.query = this.$route.query.query || ""
        this.filter.group = localStorage.getItem("selectedGroup") || ""

        this.loadTables()

        EventBus.on("update-selected-group", () => {
            this.filter.group = localStorage.getItem("selectedGroup") || ""
            this.updateFilter()
        })
    },
    methods: {
        async loadTables() {
            this.paginationKey = Math.random()

            const response = await Api.database.tables.list(
                this.currentPage,
                this.showPerPage,
                this.filter
            )

            this.tableList = response.data.items
            this.total = response.data.total
        },
        async updatePage(page) {
            this.$router.replace({
                query: {
                    page: page,
                    query: this.filter.query
                }
            })

            this.currentPage = page
            this.loadTables()
        },
        async duplicateTable(table_id) {
            var name = prompt(
                "Esta operação poderá demorar alguns minutos, dependendo a quantidade de registros existentes na tabela.\n\n Digite o nome da nova tabela:"
            )

            if (!name) {
                return
            }

            try {
                await Api.database.tables.duplicate(table_id, name)
                await this.loadTables()
            } catch (error) {
                var message = error.message

                if (error.response.data && error.response.data.message) {
                    message = error.response.data.message
                }

                EventBus.emit("message", {
                    type: "danger",
                    message: message
                })
            }
        },
        async exportTableStructure(id) {
            // export JSON data from table
            try {
                const response = await Api.database.tables.get(id)
                var table = JSON.parse(JSON.stringify(response.data))

                delete table._id
                delete table.customer_id
                delete table.workspace_id
                delete table.entities_count
                delete table.group

                const dataStr =
                    "data:text/json;charset=utf-8," +
                    encodeURIComponent(JSON.stringify(table))
                const downloadAnchorNode = document.createElement("a")
                downloadAnchorNode.setAttribute("href", dataStr)
                downloadAnchorNode.setAttribute(
                    "download",
                    this.slugify(table.name) + ".db.json"
                )
                document.body.appendChild(downloadAnchorNode) // required for firefox
                downloadAnchorNode.click()
                downloadAnchorNode.remove()
            } catch (error) {
                var message = error.message

                if (error.response.data && error.response.data.message) {
                    message = error.response.data.message
                }

                EventBus.emit("message", {
                    type: "danger",
                    message: message
                })
            }
        },

        slugify(str) {
            return str
                .toLowerCase()
                .replace(/ /g, "-")
                .replace(/[^\w-]+/g, "")
        },

        updateFilter() {
            this.$router.replace({
                query: {
                    page: 1,
                    query: this.filter.query
                }
            })

            this.loadTables()
        },
        formatDate(date) {
            return moment(date).format("DD/MM/YYYY HH:mm:ss")
        },
        groupName(group_id) {
            if (!group_id) return null

            var group = null
            store.groups.forEach((item) => {
                if (item._id == group_id) {
                    group = item
                }
            })

            return group ? group.name : null
        }
    }
}
</script>
