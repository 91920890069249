<template>
    <div>
        <div
            class="modal modal-blur fade"
            id="modal-flow-template-gallery"
            tabindex="-1"
            style="display: none"
            aria-modal="true"
            role="dialog"
        >
            <div class="modal-dialog modal-xl" role="document">
                <div class="modal-content">
                    <div class="modal-header">
                        <h5 class="modal-title">
                            Galeria de templates de Flows
                        </h5>
                        <button
                            type="button"
                            class="btn-close"
                            data-bs-dismiss="modal"
                            aria-label="Close"
                            id="closeFormButton"
                        ></button>
                    </div>
                    <div class="modal-body">
                        <div class="row">
                            <div class="col-3">
                                <h4>Filtrar por tag</h4>
                                <ul class="filter-tag-list">
                                    <li
                                        v-for="tag in tags"
                                        :key="tag.tag"
                                        v-bind:class="
                                            currentFilterTag &&
                                            tag.tag == currentFilterTag
                                                ? 'active'
                                                : ''
                                        "
                                    >
                                        <a
                                            role="button"
                                            tabindex="0"
                                            @click="filterByTag(tag.tag)"
                                        >
                                            {{ tag.tag ? tag.tag : "Todos" }}
                                            <span class="text-muted"
                                                >({{ tag.count }})</span
                                            >
                                        </a>
                                    </li>
                                </ul>
                            </div>
                            <div class="col-9 scroller">
                                <div
                                    class="row mb-3"
                                    v-for="flowTemplate in flowTemplateList"
                                    :key="flowTemplate._id"
                                >
                                    <!-- <div class="card"> -->
                                    <div class="row">
                                        <div class="col-5">
                                            <img
                                                v-bind:src="
                                                    flowTemplate.imageData
                                                "
                                                v-bind:alt="flowTemplate.name"
                                                class="my-2 border rounded-3"
                                            />
                                        </div>
                                        <div class="col-7">
                                            <div class="card-body">
                                                <h3>{{ flowTemplate.name }}</h3>
                                                <p class="text-muted">
                                                    {{
                                                        flowTemplate.description
                                                    }}
                                                </p>
                                                <p>
                                                    <span
                                                        v-for="tag in flowTemplate.tags"
                                                        :key="tag"
                                                    >
                                                        <span
                                                            class="badge bg-azure-lt"
                                                            >{{ tag }}</span
                                                        >&nbsp;
                                                    </span>
                                                </p>
                                                <p>
                                                    <button
                                                        class="btn"
                                                        @click="
                                                            sendToEditor(
                                                                flowTemplate.data
                                                            )
                                                        "
                                                        id="sendToEditorButton"
                                                    >
                                                        <i
                                                            class="ti ti-file-import"
                                                        ></i>
                                                        Importar para o editor
                                                    </button>
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                    <!-- </div> -->
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import AccountApi from "../services/account-api"

export default {
    name: "FlowTemplateGallery",
    data() {
        return {
            flowTemplateList: [],
            tags: [],
            currentFilterTag: "",
            modal: null
        }
    },
    async mounted() {
        this.modal = new bootstrap.Modal(
            document.getElementById("modal-flow-template-gallery"),
            {
                keyboard: false
            }
        )

        await this.loadFlowTemplates()
        await this.loadTags()

        this.modal.show()
    },
    methods: {
        async loadTags() {
            var response = await AccountApi.flowTemplates.getTags()
            this.tags = response.data
        },
        async loadFlowTemplates() {
            var page = 1
            var showPerPage = 100
            var filter = {
                query: "",
                tag: this.currentFilterTag
            }
            var response = await AccountApi.flowTemplates.list(
                page,
                showPerPage,
                filter
            )
            this.flowTemplateList = response.data
        },
        async filterByTag(tag) {
            this.currentFilterTag = tag
            await this.loadFlowTemplates(tag)
        },
        async sendToEditor(data) {
            this.$emit("selected-flow", data)
            this.modal.hide()
        }
    }
}
</script>
