<template>
    <div class="modal modal-blur fade" id="modal-flow-configurations" tabindex="-1" aria-modal="true" role="dialog">
        <div class="modal-dialog modal-xl" role="document">
            <div class="modal-content">
                <div class="modal-header">
                    <h5 class="modal-title">Opções do Flow</h5>
                    <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"
                        id="closeFormButton"></button>
                </div>
                <div class="modal-body pt-2">
                    <div class="modal-body-container">
                        <div class="row">
                            <div class="col-12">
                                <label for="rateLimit" class="form-label">Limite de execuções simultâneas</label>
                                <input type="number" min="0" class="form-control" id="rateLimit" v-model="config.rate_limit">
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: "FlowConfigurationsModal",
    props: {
        initConfig: {
            type: Object
        }
    },
    data() {
        return {
            config: {
                rate_limit: 0
            }
        }
    },
    mounted() {
        var modal = new bootstrap.Modal(
            document.getElementById("modal-flow-configurations"),
            {
                keyboard: false
            }
        )

        modal.show()

        if (this.initConfig) {
            this.config = this.initConfig
        }

        this.$watch('config', function (value) {
            this.$emit('update', value)
        }, { deep: true })
    }
}
</script>
