<template>
    <div>
        <div class="row">
            <div class="col">
                <div class="card">
                    <div class="card-body">
                        <div class="row">
                            <div class="col">
                                <h3 class="card-title">
                                    Remover grupo
                                </h3>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col">
                                <p class="mb-3">
                                    Tem certeza que deseja remover o grupo <strong>{{ group.name }}</strong>?<br />
                                </p>
                                <div class="form-group">
                                    <label class="form-label">Para confirmar a remoção, digite abaixo texto
                                        <span class="bg-red-lt px-1">{{group.name}}</span>:</label>
                                    <input type="text" class="form-control" v-model="nameConfirmation"
                                        id="confirmationInput" />
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="card-footer">
                        <div class="row">
                            <div class="col">
                                <a href="/groups/" class="btn" id="cancelAndBackButton">
                                    <i class="ti ti-arrow-left"></i>
                                    Cancelar e voltar
                                </a>
                            </div>
                            <div class="col-auto">
                                <a v-on:click="removeGroup" class="btn btn-danger" id="removeGroupButton">
                                    <i class="ti ti-trash"></i>
                                    Remover grupo
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import Api from "../services/api"
import EventBus from "../services/event-bus"

export default {
    name: "GroupsDeletePage",
    data() {
        return {
            nameConfirmation: "",
            group: {}
        }
    },
    async mounted() {
        var id = this.$route.params.id
        var response = await Api.groups.get(id)
        this.group = response.data
    },
    methods: {
        async removeGroup() {
            var id = this.$route.params.id

            try {
                if (this.validate()) {
                    await Api.groups.delete(id)

                    this.$router.push("/groups")

                    EventBus.emit("message", {
                        type: "success",
                        message: "O grupo foi removida com sucesso!"
                    })

                    EventBus.emit("update-groups-list")
                }
            } catch (error) {
                EventBus.emit("message", {
                    type: "error",
                    message: "Erro ao remover o grupo:" + error
                })
            }
        },
        validate() {
            if (this.group.name !== this.nameConfirmation) {
                EventBus.emit("message", {
                    type: "warning",
                    message:
                        "O nome do grupo não confere com o nome digitado!"
                })

                return false
            }
            return true
        }
    }
}
</script>
