<template>
    <div>
        <div class="row mb-3">
            <div class="col">
                <label class="form-label">
                    <i class="ti ti-calendar"></i>
                    Período
                </label>
                <div class="form-selectgroup">
                    <label class="form-selectgroup-item">
                        <input type="radio" name="period" v-model="periodType" value="1h" class="form-selectgroup-input"
                            id="1hPeriodInput" />
                        <span class="form-selectgroup-label">1h</span>
                    </label>
                    <label class="form-selectgroup-item">
                        <input type="radio" name="period" v-model="periodType" value="1d" class="form-selectgroup-input"
                            id="1dPeriodInput" />
                        <span class="form-selectgroup-label">24h</span>
                    </label>
                    <label class="form-selectgroup-item">
                        <input type="radio" name="period" v-model="periodType" value="7d" class="form-selectgroup-input"
                            id="7dPeriodInput" />
                        <span class="form-selectgroup-label">7d</span>
                    </label>
                    <label class="form-selectgroup-item">
                        <input type="radio" name="period" v-model="periodType" value="15d"
                            class="form-selectgroup-input" id="15dPeriodInput" />
                        <span class="form-selectgroup-label">15d</span>
                    </label>
                    <label class="form-selectgroup-item">
                        <input type="radio" name="period" v-model="periodType" value="custom"
                            class="form-selectgroup-input">
                        <span class="form-selectgroup-label">Personalizado</span>
                    </label>

                    <div class="col-auto" v-show="periodType == 'custom'">
                        <div class="input-group">
                            <div class="input-group-text">De</div>
                            <input type="datetime-local" max="2099-12-12T00:00" step="1" class="form-control" v-model="date_start"
                                id="filterStartDateInput" @blur="updateRange" @keyup.enter="updateRange"/>
                            <div class="input-group-text">Até</div>
                            <input type="datetime-local" step="1" max="2099-12-12T00:00" class="form-control" v-model="date_end"
                                id="filterEndDateInput" @blur="updateRange" @keyup.enter="updateRange"/>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: "DateRangePicker",
    props: {
        periodTypeDefault: {
            type: String,
            default: "1h"
        },
        dateStart: {
            type: String,
            default: ""
        },
        dateEnd: {
            type: String,
            default: ""
        }
    },
    data() {
        return {
            periodType: "1d",
            date_start: "",
            date_end: ""
        }
    },
    mounted() {
        this.$watch("periodTypeDefault", function (value) {
            this.periodType = value
        })

        this.periodType = this.periodTypeDefault
        this.date_start = this.dateStart
        this.date_end = this.dateEnd

        this.$watch("periodType", (value) => {
            if (value != 'custom') {
                this.$emit("periodChange", value)
            }
        })
    },
    methods: {
        updateRange: function () {
            this.$emit("periodChange", this.periodType, {
                date_start: this.date_start,
                date_end: this.date_end
            })
        }
    }
}
</script>
