<template>
    <div>
        <div class="card">
            <div class="card-header">
                <div class="col">
                    <h3 class="card-title">
                        <i class="ti ti-forms"></i>
                        Forms
                    </h3>
                    <p class="text-muted">Gestão de formulários do workspace</p>
                </div>
                <div class="col-auto">
                    <a href="/forms/new" class="btn btn-primary" id="newFormButton">
                        <i class="ti ti-plus"></i>
                        Criar novo form
                    </a>
                </div>
            </div>

            <div class="card-body">
                <div class="row">
                    <div class="col-3 mb-2">
                        <input type="text" class="form-control" v-model="filter.query" @change="updateFilter"
                            placeholder="Buscar forms" id="searchFormsInput" />
                    </div>
                </div>
            </div>

            <div class="card-body">
                <div v-if="formList.length">
                    <table class="table table-vcenter card-table">
                        <thead>
                            <tr>
                                <th>Nome do formulário</th>
                                <th>Status</th>
                                <th>Grupo</th>
                                <th>Criação</th>
                                <th>Atualização</th>
                                <th>Ações</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr v-for="form in formList" :key="form._id">
                                <td>{{ form.name }}</td>
                                <td>
                                    <span class="badge bg-green text-green-fg" v-if="form.status == 'published'">{{
                                        form.status }}</span>
                                    <span class="badge" v-if="form.status == 'draft'">{{ form.status }}</span>
                                </td>
                                <td>
                                    <span v-if="!groupName(form.group)" class="badge bg-gray-lt text-muted">Sem grupo</span>
                                    <span v-else class="badge bg-blue-lt">{{ groupName(form.group) }}</span>
                                </td>
                                <td>{{ formatDate(form.created_at) }}</td>
                                <td>{{ formatDate(form.updated_at) }}</td>
                                <td>
                                    <span>&nbsp;</span>
                                    <a :href="workspace.engine_url +
                                        'forms/' +
                                        form._id
                                        " class="btn btn-sm showFormButton" target="_blank">
                                        <i class="ti ti-eye"></i>
                                        Visualizar
                                    </a>
                                    <span>&nbsp;</span>
                                    <a :href="'/forms/edit/' + form._id"
                                        class="btn btn-sm btn-ghost-secondary editFormButton">
                                        <i class="ti ti-pencil"></i>
                                        Editar
                                    </a>
                                    <span>&nbsp;</span>
                                    <a :href="'/forms/delete/' + form._id"
                                        class="btn btn-sm btn-ghost-danger deleteFormButton">
                                        <i class="ti ti-trash"></i>
                                        Excluir
                                    </a>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>

            <div class="card-body" v-if="!formList.length && !filter.query">
                <div class="empty">
                    <p class="empty-title">Nenhum form criado ainda.</p>
                    <p class="empty-subtitle text-muted">
                        Clique no botão abaixo para criar seu primeiro form!
                    </p>
                    <div class="empty-action">
                        <a href="/forms/new" class="btn btn-primary" id="newFormButton">
                            <i class="ti ti-plus"></i>
                            Criar form
                        </a>
                    </div>
                </div>
            </div>

            <div class="card-body" v-if="!formList.length && filter.query">
                <div class="empty bg-muted-lt">
                    <p class="empty-title">
                        Nenhum form encontrado para esta busca.
                    </p>
                </div>
            </div>

            <div class="card-footer">
                <Pagination :key="paginationKey" :showPerPage="showPerPage" :currentPage="currentPage" :total="total"
                    v-if="total > showPerPage" @pageChange="updatePage" />
            </div>
        </div>
    </div>
</template>

<script>
import moment from "moment"
import Api from "../services/api"
import Pagination from "./Pagination"
import EventBus from "../services/event-bus"
import { store } from "../store"

export default {
    name: "FormsList",
    components: {
        Pagination
    },
    data() {
        return {
            workspace: {},
            formList: [],
            filter: {
                query: ""
            },
            paginationKey: 0,
            showPerPage: 10,
            currentPage: 1,
            total: 0
        }
    },
    async mounted() {
        this.currentPage = parseInt(this.$route.query.page) || 1
        this.filter.query = this.$route.query.query || ""
        this.filter.group = localStorage.getItem("selectedGroup") || ""
        this.workspace = store.workspace

        this.loadForms()

        EventBus.on("update-selected-group", () => {
            this.filter.group = localStorage.getItem("selectedGroup") || ""
            this.updateFilter()
        })
    },
    methods: {
        async loadForms() {
            this.paginationKey = Math.random()

            const response = await Api.forms.list(
                this.currentPage,
                this.showPerPage,
                this.filter
            )

            this.formList = response.data.items
            this.total = response.data.total
        },
        async updatePage(page) {
            this.$router.replace({
                query: {
                    page: page,
                    query: this.filter.query
                }
            })

            this.currentPage = page
            this.loadForms()
        },
        updateFilter() {
            this.$router.replace({
                query: {
                    page: 1,
                    query: this.filter.query
                }
            })

            this.loadForms()
        },
        formatDate(date) {
            return moment(date).format("DD/MM/YYYY HH:mm:ss")
        },
        groupName(group_id) {
            if (!group_id) return null

            var group = null
            store.groups.forEach((item) => {
                if (item._id == group_id) {
                    group = item
                }
            })

            return group ? group.name : null
        }
    }
}
</script>
