module.exports = {
    limitGroupName(group) {
        let groupName = group.name

        if (groupName.length > 30) {
            groupName = groupName.substring(0, 30) + "..."
        }

        return {
            _id: group._id,
            name: groupName
        }
    },

    parseValidGroup(selectedGroup, groups) {
        if (!selectedGroup) {
            return ""
        }

        if (typeof selectedGroup !== "string") {
            selectedGroup = selectedGroup._id
        }

        const isValidGroup = groups.find(group => group._id === selectedGroup)
        
        return isValidGroup ? selectedGroup : ""
    }
}