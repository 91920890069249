<template>
    <div>
        <div class="page-wrapper">
            <div class="page-body">
                <div class="row g-2">
                    <div class="col">
                        <div class="card">
                            <div class="card-header">
                                <div class="col">
                                    <h3 class="card-title">
                                        <i class="ti ti-folders"></i>
                                        Editar grupo
                                    </h3>
                                </div>
                            </div>

                            <div class="row">
                                <div class="col">
                                    <div class="card-body">
                                        <div class="row mb-3">
                                            <div class="col">
                                                <label class="form-label required">Nome do grupo</label>
                                                <input type="text" class="form-control" placeholder=""
                                                    v-model="group.name" />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div class="card-footer">
                                <div class="row">
                                    <div class="col"></div>
                                    <div class="col-auto align-right">
                                        <button class="btn btn-primary" @click="saveGroup">
                                            <i class="ti ti-device-floppy"></i>
                                            Salvar
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { store } from "../store"
import Api from "../services/api"
import EventBus from "../services/event-bus"

export default {
    name: "GroupEditPage",
    data() {
        return {
            group: {
                name: "Novo grupo"
            }
        }
    },
    async mounted() {
        store.showSidebar = true
        store.showHeader = true
        store.showBackButton = true
        store.backUrl = "/groups"

        // new
        if (this.$route.params.id) {
            var response = await Api.groups.get(this.$route.params.id)
            this.group = response.data
        }
    },
    methods: {
        async saveGroup() {
            try {
                const group = {
                    ...this.group,
                    name: this.group.name.trim()
                }

                if (!group.name) {
                    EventBus.emit("message", {
                        type: "danger",
                        message: "O nome do grupo é obrigatório!"
                    })
                    return
                }

                if (group.name.length > 50) {
                    EventBus.emit("message", {
                        type: "danger",
                        message: "O nome do grupo deve ter no máximo 50 caracteres!"
                    })
                    return
                }

                // new
                if (!this.$route.params.id) {
                    await Api.groups.create(group)
                } else {
                    // update
                    await Api.groups.update(
                        group._id,
                        group
                    )
                }

                EventBus.emit("message", {
                    type: "success",
                    message: "O grupo foi salvo com sucesso!"
                })

                this.$router.push("/groups")

                EventBus.emit('update-groups-list')
            } catch (e) {
                var message = e.message
                if (e.response && e.response.data && e.response.data.message) {
                    message = e.response.data.message
                }

                EventBus.emit("message", {
                    type: "danger",
                    message: "Ocorreu um erro ao salvar o grupo: " + message
                })
            }
        }
    }
}
</script>
